import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, navigate } from "gatsby";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";

import PageHeader from "../components/common/page-header";
import Breadcrumb from "../components/common/breadcrumb";
import Seo from "../components/common/SEO";

import { getUser, getIsAuthenticated, login } from "../reducers/user";

const Collections = (props) => {
  // const [step, setStep] = useState(1);
  const [errors, setErrors] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Handle fields change

  const handleProcessing = (processing = false, errors = null) => {
    setErrors(errors);
    setProcessing(processing);
  };
  const submitForm = async (e) => {
    e.preventDefault();
    handleProcessing(true);
    props
      .loginUser({ email: email, password: password })
      .then((data) => {
        handleProcessing();
        // props.history.push("/dashboard");
        navigate("/dashboard/");
      })
      .catch((err) => {
        if (err.response.status === 400)
          if (err.response.data.errors) {
            handleProcessing(false, err.response.data.errors);
          } else if (err.response.data.error) {
            handleProcessing(false, [{ msg: err.response.data.error }]);
          }
        //   else if (err.response.status === 500) setOpenSnackbar(true);
      });
  };

  return (
    <LoadingOverlay>
      <Loader text="Processing ..." loading={processing} />

      <Seo title="Login" />
      <div className="main">
        <h1 className="d-none">Login</h1>
        <PageHeader title="Login" />
        <Breadcrumb />

        <div className="page-content">
          <div className="container">
            <hr className="mb-4" />
            <div className="login-page  pt-1 pb-1 pt-md-1 pb-md-1 pt-lg-1 pb-lg-1">
              <div className="container">
                <div className="form-box">
                  <div className="form-tab">
                    <div className="tab-content">
                      <div>
                        {" "}
                        <h5>Sign in</h5>
                      </div>{" "}
                      {errors && (
                        <div className="row">
                          <div className="col">
                            <div
                              className="alert alert-danger w-100"
                              role="alert"
                            >
                              {typeof errors === "string" ? (
                                errors
                              ) : (
                                <ul>
                                  {errors.map((err, i) => (
                                    <li
                                      key={i}
                                      style={{ display: "list-item" }}
                                    >
                                      {err.msg}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      <form>
                        <div className="form-group">
                          <label htmlFor="singin-email-2">
                            Email address *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="singin-email-2"
                            name="email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            defaultValue={email}
                            required
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="singin-password-2">Password *</label>
                          <input
                            type="password"
                            className="form-control"
                            id="singin-password-2"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            defaultValue={password}
                            required
                          />
                        </div>

                        <div className="form-footer">
                          <Link to="/forgetpassword/" className="forgot-link">
                            Forgot Your Password?
                          </Link>
                          <div className="forgot-link">
                            Don't have an account?{" "}
                            <Link to="/signup/">Create an Account</Link>
                          </div>{" "}
                          <button
                            onClick={submitForm}
                            type="submit"
                            className="btn btn-outline-primary-2"
                            style={{ color: "#000" }}
                          >
                            <span>Login</span>
                            <i className="icon-long-arrow-right"></i>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

const mapStateToProps = (state) => ({
  loggedInUser: getUser(state),
  isAuthenticated: getIsAuthenticated(state),
});

const mapDispatchToProps = (dispatch) => ({
  loginUser: (data) => dispatch(login(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Collections);
