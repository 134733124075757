import React from "react";
import "react-overlay-loader/styles.css";

import MainLayout from "../layouts/MainLayout";
import LoginPage from "../components/login";
const Login = () => {
  return (
    <MainLayout>
      <LoginPage />
    </MainLayout>
  );
};

export default Login;
